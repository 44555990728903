<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2">Monitoring Laporan Pejabat Barang dan Jasa</h3>
          </template>
          <v-row>
            <v-col>
              <crud
              :crudSettings="crudSettings"
              :headers="headers"
              :responseData="{data:items}"
              :detailData="detailData"
              :forceUpdateTable="forceUpdate"
              @onTableChange="updateTable"
              >
              <template v-slot:prepend-header>
                <v-select style="max-width:200px" label="SKPD" hide-details class="mx-2"></v-select>
                <v-divider class="mx-2" inset vertical/>
                <v-select style="max-width:200px" :items="months" label="Bulan" hide-details class="mx-2"></v-select>
                <v-divider class="mx-2" inset vertical/>
                <v-btn-toggle
                  v-model="filter"
                  color="primary"
                  dense
                >
                  <v-btn>
                    Semua
                  </v-btn>
                  <v-btn>
                    Barjas Pokja
                  </v-btn>
                  <v-btn>
                    Barjas SKPD
                  </v-btn>
                  <v-btn>
                    PPHP
                  </v-btn>
                  <v-btn>
                    PPK
                  </v-btn>
                  <v-btn>
                    PBJ
                  </v-btn>
                </v-btn-toggle>
                <v-divider class="mx-2" inset vertical/>
              </template>
            </crud>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {months} from '@/utils/lib'
import _ from 'lodash'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      filter:'',
      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Nama Pegawai', value:'nama_peg', sortable:false, class:'header-index-profile'},
        {text:'Tanggal BAP', value:'tgl', sortable:false, class:'header-index-profile'},
        {text:'No BAP', value:'jenis', sortable:false, class:'header-index-profile'},
        {text:'Pagu', value:'surat', sortable:false, class:'header-index-profile'},
        {text:'Paket Pekerjaan', value:'foto', sortable:false, class:'header-index-profile'},
        {text:'Paket Dinas', value:'foto', sortable:false, class:'header-index-profile'},
        {text:'Evidence / Bukti BAP', value:'foto', sortable:false, class:'header-index-profile'},
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
    }
  },

  watch:{},

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{
    months(){
      return months
    }
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){},
  },
}
</script>

<style lang="css" scoped>
</style>
